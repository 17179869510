<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The hypothetical first-order reaction shown below has a rate constant of
        <stemble-latex content="$\text{k}=$" />
        <number-value :value="rateConstant" unit="\text{s}^{-1}\text{.}" />
      </p>

      <p class="mb-3 pl-8">
        <chemical-latex content="A -> products" />
      </p>

      <p class="mb-2">
        If you started with
        <number-value :value="Anaught" unit="\text{M}" />
        of
        <stemble-latex content="$\text{A,}$" />
        how long would it take (in seconds) for the concentration to decrease to
        <number-value :value="Atee" unit="\text{M?}" />
      </p>

      <calculation-input
        v-model="inputs.time"
        prepend-text="$\text{t:}$"
        append-text="$\text{s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question91',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        time: null,
      },
    };
  },
  computed: {
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
    Anaught() {
      return this.taskState.getValueBySymbol('Anaught').content;
    },
    Atee() {
      return this.taskState.getValueBySymbol('Atee').content;
    },
  },
};
</script>
